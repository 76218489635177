<template>
    <div class="">
        <!-- <div class="uXMilnO">
        <Header @parterr="parterr" :upadteQuotaStatus="getUpadteQuotaStatus"></Header>
        </div> -->
        <div class="cont_m">
          <div  style="display:flex;justify-content: end;">
          <Menu :percentageerr="percentageerr" :menuInfos="menuInfos" @getCurLink="getKey"></Menu>
          <div class="right_box">
            <router-view @upadteQuota="getUpdateQuato"/>
          </div>
        </div>
        <PermissionDialog></PermissionDialog>
    </div>
</div>
</template>
<script>

import PermissionDialog from '@/components/power/PermissionDialog.vue';
import { getDashboard} from '@/api/userApi.js'
import Menu from './components/leftMenu.vue'
import Header from '../layout/header.vue'
export default {
    components: { Header,Menu,PermissionDialog},
    data() {
        return {
            getUpadteQuotaStatus:false,
            dashboadObj:{},
            menuInfos:[],
            percentageerr:false
        }
    },
    computed: {
    },
    created() {
        // this.dashboadInit()
    },
    methods: {
        parterr(value){
            this.percentageerr=value
        },
        getUpdateQuato(val){
            this.getUpadteQuotaStatus=val
        },

        //获取菜单权限
        // dashboadInit() {
        //     getDashboard().then((result) => {
        //         if (result && result.code) {
        //             this.menuInfos=[]
        //             let data = result.data
        //             //菜单权限
        //             let LoginglanceType=''
        //             let UsagelistType=''
        //             let OrderlistType=''
        //             let RechargelistType=''
        //             let loginDurationType=''
        //             let menuList=data.roleInfos[0].sysMenuInfos
        //             menuList.forEach(item => {
        //                 if(item.title=="Login at a glance"){
        //                 LoginglanceType=item.type
        //                 }
        //                 else if(item.title=="Usage list"){
        //                 UsagelistType=item.type
        //                 }
        //                 else if(item.title=="Order List"){
        //                 OrderlistType=item.type
        //                 }
        //                 else if(item.title=="Recharge list"){
        //                 RechargelistType=item.type
        //                 }
        //                 else if(item.title=="List of login duration and times"){
        //                 loginDurationType=item.type
        //                 }
        //                 else{
        //                     this.menuInfos.push(item)
        //                 }
        //             });
        //             this.dashboadObj = {
        //                 LastLoginDuration: data.lastLogDur,
        //                 TotalLogins: data.totalLogins,
        //                 AverageDuration: data.avgDuration,
        //                 LastViewedQuantity: data.lastView,
        //                 QuantityOfThisMonth: data.monthView,
        //                 TotalViews: data.totalView,
        //                 LastUpdatedQuantity: data.lastQua,
        //                 UpdateQuantityThisMonth: data.monthQua,
        //                 TotalUpdates: data.totalUpdate,
        //                 LastRechargeAmount: data.lastAmount,
        //                 AccumulatedRechargeAmount: data.rechAmount,
        //                 RemainingQuota: data.remainQuota,
        //                 tab1:LoginglanceType,
        //                 tab2:UsagelistType,
        //                 tab3:OrderlistType,
        //                 tab4:RechargelistType,
        //                 tab5:loginDurationType,
        //             }
        //         }
        //     })
        // },

        //刷新菜单
        getKey(val){
            if(val===0){
                // this.dashboadInit()
            }
        }
    }
}
</script>
<style scoped>
@media screen and (max-width: 1441px) {

  .right_box{
    /* width: 82% ; */
        width: calc(100% - 27.5rem) !important;
}
}
.right_box{
    /* width: 82% ; */
    float: right;
        width: calc(100% - 26rem);
}
.cont_m{
        background: #F5F5F5;
}
.uBmamJ5 {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
}
</style>